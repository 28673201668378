import { useTranslation } from 'next-i18next';
import { Url } from 'next/dist/shared/lib/router/router';
import { StaticImageData } from 'next/image';
import { useRouter } from 'next/router';
import { getFormattedDate } from '@/utils/time/dateFormatter';
import { BlogPostCardVariant, CarouselArrow, Chevron, PostBody, PostButton, PostContent, PostDate, PostFooter, PostImage, PostImageWrapper, PostLabel, PostMeta, PostWrapper } from './BlogPostCard.styles';
export interface BlogPostCardProps {
  image: string | StaticImageData;
  imageAlt: string;
  title: string;
  description: string;
  path: Url;
  publishedAt: Date;
  variant?: BlogPostCardVariant;
  showArrows?: boolean;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  className?: string;
}
export const BlogPostCard = ({
  description,
  image,
  imageAlt,
  path,
  title,
  publishedAt,
  variant = 'tile',
  onNextClick,
  onPrevClick,
  showArrows,
  className
}: BlogPostCardProps) => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const {
    locale
  } = router;
  const getSize = (variant: BlogPostCardVariant) => {
    switch (variant) {
      case 'tile':
        return {
          width: 500,
          height: 500
        };
      case 'listItem':
        return {
          width: 400,
          height: 400
        };
      case 'bigTile':
        return {
          width: 900,
          height: 600
        };
      default:
        return {
          width: 900,
          height: 900
        };
    }
  };
  return <PostWrapper href={path} variant={variant} className={className} data-sentry-element="PostWrapper" data-sentry-component="BlogPostCard" data-sentry-source-file="BlogPostCard.tsx">
      <PostImageWrapper variant={variant} data-sentry-element="PostImageWrapper" data-sentry-source-file="BlogPostCard.tsx">
        <PostImage src={image} alt={imageAlt} width={getSize(variant).width} height={getSize(variant).height} variant={variant} data-sentry-element="PostImage" data-sentry-source-file="BlogPostCard.tsx" />
        {showArrows && <>
            <CarouselArrow side="left" onClick={e => {
          e.preventDefault();
          onPrevClick && onPrevClick();
        }}>
              <Chevron side="left" />
            </CarouselArrow>
            <CarouselArrow side="right" onClick={e => {
          e.preventDefault();
          onNextClick && onNextClick();
        }}>
              <Chevron side="right" />
            </CarouselArrow>
          </>}
      </PostImageWrapper>
      <PostBody variant={variant} data-sentry-element="PostBody" data-sentry-source-file="BlogPostCard.tsx">
        <PostMeta variant={variant} data-sentry-element="PostMeta" data-sentry-source-file="BlogPostCard.tsx">
          <PostLabel variant={variant} data-sentry-element="PostLabel" data-sentry-source-file="BlogPostCard.tsx">{title}</PostLabel>
          <PostContent variant={variant} data-sentry-element="PostContent" data-sentry-source-file="BlogPostCard.tsx">{description}</PostContent>
        </PostMeta>
        <PostFooter data-sentry-element="PostFooter" data-sentry-source-file="BlogPostCard.tsx">
          <PostDate data-sentry-element="PostDate" data-sentry-source-file="BlogPostCard.tsx">
            {getFormattedDate(publishedAt, 'dd MMMM y', locale)}
          </PostDate>
          <PostButton variant="gray-fill" size="xs" data-sentry-element="PostButton" data-sentry-source-file="BlogPostCard.tsx">
            {t('readMore')}
          </PostButton>
        </PostFooter>
      </PostBody>
    </PostWrapper>;
};