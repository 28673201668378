import ArrowRightIcon from 'public/icons/arrow-right-s-fill.svg';
import { ArticleButtonWrapper, HelpCenterArticlesItemTitle } from './ArticleButton.styles';
interface ArticleButtonProps {
  title: string;
  href: string;
}
export const ArticleButton = ({
  title,
  href
}: ArticleButtonProps) => {
  return <ArticleButtonWrapper href={href} title={title} data-sentry-element="ArticleButtonWrapper" data-sentry-component="ArticleButton" data-sentry-source-file="ArticleButton.tsx">
      <HelpCenterArticlesItemTitle data-sentry-element="HelpCenterArticlesItemTitle" data-sentry-source-file="ArticleButton.tsx">{title}</HelpCenterArticlesItemTitle>
      <ArrowRightIcon data-sentry-element="ArrowRightIcon" data-sentry-source-file="ArticleButton.tsx" />
    </ArticleButtonWrapper>;
};