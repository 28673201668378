import { useTranslation } from 'next-i18next';
import boltzAvatar from 'public/images/creators/boltz.png';
import csrAvatar from 'public/images/creators/csr.png';
import izakAvatar from 'public/images/creators/izak.png';
import jabutiAvatar from 'public/images/creators/jabuti.png';
import merghaniAvatar from 'public/images/creators/merghani.png';
import metaAvatar from 'public/images/creators/meta.png';
import neexAvatar from 'public/images/creators/neex.png';
import nikolarnAvatar from 'public/images/creators/nikolarn.png';
import palashAvatar from 'public/images/creators/palash.png';
import tarifaAvatar from 'public/images/creators/tarifa.png';
import tiagovskiAvatar from 'public/images/creators/tiagovski.png';
import OfficialPartners from '@/domains/trustedUs/OfficialPartners';
import { ReadyToJoin } from '@/domains/trustedUs/ReadyToJoin';
import { ChannelProps } from './Channel/Channel';
import { ChannelsCarousel } from './ChannelsCarousel';
import { TrustedByWrapper } from './TrustedBy.styles';
import { SectionTitle } from '../landing.styles';
const creators: ChannelProps[] = [{
  name: 'Izak',
  avatar: izakAvatar.src,
  subscriptions: '2,17 mln',
  link: 'https://www.youtube.com/@izakLIVE'
}, {
  name: 'Tarifa',
  avatar: tarifaAvatar.src,
  subscriptions: '1.98 mln',
  link: 'https://www.youtube.com/c/bytarifagaming'
}, {
  name: 'Neex',
  avatar: neexAvatar.src,
  subscriptions: '351 k',
  link: 'https://www.twitch.tv/neexcsgo'
}, {
  name: 'Merghani',
  avatar: merghaniAvatar.src,
  subscriptions: '605 k',
  link: 'https://www.twitch.tv/xmerghani'
}, {
  name: 'Boltz',
  avatar: boltzAvatar.src,
  subscriptions: '164 k',
  link: 'https://www.youtube.com/boltz'
}, {
  name: 'Tiagovski',
  avatar: tiagovskiAvatar.src,
  subscriptions: '186 k',
  link: 'https://www.youtube.com/@VSKI555yt'
}, {
  name: 'Jabuti',
  avatar: jabutiAvatar.src,
  subscriptions: '1.38 mln',
  link: 'https://www.youtube.com/jabuti'
}, {
  name: 'Csr',
  avatar: csrAvatar.src,
  subscriptions: '415 k',
  link: 'https://www.youtube.com/@CSRfps'
}, {
  name: 'Meta',
  avatar: metaAvatar.src,
  subscriptions: '163 k',
  link: 'https://www.youtube.com/@METAMN'
}, {
  name: 'Palash',
  avatar: palashAvatar.src,
  subscriptions: '257 k',
  link: 'https://www.youtube.com/@Palashcsgo'
}, {
  name: 'Nikolarn',
  avatar: nikolarnAvatar.src,
  subscriptions: '566 k',
  link: 'https://www.twitch.tv/nikolarn'
}];
export const TrustedBy = () => {
  const {
    t
  } = useTranslation('home');
  return <TrustedByWrapper data-sentry-element="TrustedByWrapper" data-sentry-component="TrustedBy" data-sentry-source-file="TrustedBy.tsx">
      <SectionTitle data-sentry-element="SectionTitle" data-sentry-source-file="TrustedBy.tsx">{t('trustedBy.title')}</SectionTitle>
      <OfficialPartners withoutDecoration data-sentry-element="OfficialPartners" data-sentry-source-file="TrustedBy.tsx" />
      <ReadyToJoin withoutOffset data-sentry-element="ReadyToJoin" data-sentry-source-file="TrustedBy.tsx" />
      <ChannelsCarousel items={creators} data-sentry-element="ChannelsCarousel" data-sentry-source-file="TrustedBy.tsx" />
    </TrustedByWrapper>;
};