import { Trans, useTranslation } from 'next-i18next';
import ArrowIcon from 'public/icons/arrow-right-down-fill.svg';
import NoHiddenFeesIcon from 'public/icons/gold-ingots.svg';
import TransparentPayoutIcon from 'public/icons/payout.svg';
import ExchangeSupportIcon from 'public/icons/support.svg';
import getBonusPolygonImg from 'public/images/landing/banner-get-bonus-polygon.webp';
import getBonusWeaponImg from 'public/images/landing/banner-get-bonus-weapon.webp';
import videoPlaceholder from 'public/images/landing/video-placeholder.webp';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { MobileSearchLoader } from '@/components/AnimatedLoaders';
import Image from '@/components/Image';
import { BannerText, BestSkinsBanner, BestSkinsButton, BestSkinsTitle, GetBonusBanner, GetBonusButton, GetBonusText, GetBonusTitle, GetBonusWeapon, GetBonusWeaponImage, IntroStepsTitle, IntroStepsWrapper, MovieWrapper } from './IntroSteps.styles';
import { ProfitTile } from './ProfitTile';
import { Steps } from './Steps';
export const IntroSteps = () => {
  const {
    t
  } = useTranslation('home');
  const auth = useAuth();
  const url = `${process.env.NEXT_PUBLIC_PS_CDN_URL}/intro1.webm`;
  const [hasLoadedVideo, setHasLoadedVideo] = useState(false);
  const movieRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = movieRef.current;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !hasLoadedVideo) {
        setHasLoadedVideo(true);
      }
    }, {
      threshold: 0.1
    });
    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [hasLoadedVideo]);
  return <IntroStepsWrapper data-sentry-element="IntroStepsWrapper" data-sentry-component="IntroSteps" data-sentry-source-file="IntroSteps.tsx">
      <IntroStepsTitle data-sentry-element="IntroStepsTitle" data-sentry-source-file="IntroSteps.tsx">{t('introSteps.title')}</IntroStepsTitle>
      <Steps items={[{
      title: t('introSteps.stepOne.title'),
      description: t('introSteps.stepOne.description')
    }, {
      title: t('introSteps.stepTwo.title'),
      description: t('introSteps.stepTwo.description')
    }, {
      title: t('introSteps.stepThree.title'),
      description: t('introSteps.stepThree.description')
    }]} data-sentry-element="Steps" data-sentry-source-file="IntroSteps.tsx" />
      <MovieWrapper ref={movieRef} data-sentry-element="MovieWrapper" data-sentry-source-file="IntroSteps.tsx">
        {hasLoadedVideo && <video autoPlay width="100%" loop muted preload="none" poster={videoPlaceholder.src} aria-label="alt">
            <source src={url} />
          </video>}
        {!hasLoadedVideo && <MobileSearchLoader />}
      </MovieWrapper>
      <ProfitTile icon={<NoHiddenFeesIcon />} text={<Trans ns="home" i18nKey="exchange.transparentPayout" components={{
      1: <span />
    }} />} data-sentry-element="ProfitTile" data-sentry-source-file="IntroSteps.tsx" />
      <ProfitTile icon={<ExchangeSupportIcon />} text={t('exchange.support')} data-sentry-element="ProfitTile" data-sentry-source-file="IntroSteps.tsx" />
      <ProfitTile icon={<TransparentPayoutIcon />} text={t('exchange.noHiddenFees')} data-sentry-element="ProfitTile" data-sentry-source-file="IntroSteps.tsx" />
      <BestSkinsBanner data-sentry-element="BestSkinsBanner" data-sentry-source-file="IntroSteps.tsx">
        <BestSkinsTitle data-sentry-element="BestSkinsTitle" data-sentry-source-file="IntroSteps.tsx">{t('banners.skins.title')}</BestSkinsTitle>
        <BannerText data-sentry-element="BannerText" data-sentry-source-file="IntroSteps.tsx">{t('banners.skins.description')}</BannerText>
        <BestSkinsButton onClick={() => auth.signinRedirect()} aria-label={t('banners.signInButtonAriaLabel')} data-sentry-element="BestSkinsButton" data-sentry-source-file="IntroSteps.tsx">
          <ArrowIcon data-sentry-element="ArrowIcon" data-sentry-source-file="IntroSteps.tsx" />
        </BestSkinsButton>
      </BestSkinsBanner>
      <GetBonusBanner data-sentry-element="GetBonusBanner" data-sentry-source-file="IntroSteps.tsx">
        <GetBonusTitle data-sentry-element="GetBonusTitle" data-sentry-source-file="IntroSteps.tsx">
          <Trans ns="home" i18nKey="banners.bonus.title" components={{
          color: <span />
        }} data-sentry-element="Trans" data-sentry-source-file="IntroSteps.tsx" />
        </GetBonusTitle>
        <GetBonusText data-sentry-element="GetBonusText" data-sentry-source-file="IntroSteps.tsx">{t('banners.bonus.description')}</GetBonusText>
        <GetBonusButton onClick={() => auth.signinRedirect()} data-sentry-element="GetBonusButton" data-sentry-source-file="IntroSteps.tsx">
          {t('banners.bonus.button')}
        </GetBonusButton>
        <GetBonusWeapon data-sentry-element="GetBonusWeapon" data-sentry-source-file="IntroSteps.tsx">
          <GetBonusWeaponImage src={getBonusWeaponImg} alt={t('banners.bonus.weaponAlt')} fill style={{
          objectFit: 'cover'
        }} sizes="(max-width: 992px) 90vw, (max-width: 1700px) 60vw, 1090px" data-sentry-element="GetBonusWeaponImage" data-sentry-source-file="IntroSteps.tsx" />
          <Image src={getBonusPolygonImg} alt="" style={{
          position: 'absolute',
          right: '30%',
          top: '50%'
        }} data-sentry-element="Image" data-sentry-source-file="IntroSteps.tsx" />
        </GetBonusWeapon>
      </GetBonusBanner>
    </IntroStepsWrapper>;
};