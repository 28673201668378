import { ILandingPageStatisticsDto } from '@contracts/web';
import { useQuery } from '@tanstack/react-query';
import { Trans, useTranslation } from 'next-i18next';
import ChatIcon from 'public/icons/chat-2-fill.svg';
import CheckboxIcon from 'public/icons/checkbox-fill.svg';
import TimeIcon from 'public/icons/time-fill.svg';
import UserIcon from 'public/icons/user-fill.svg';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { getStatistics } from '@/api/web';
import { AnimatedNumber } from '@/components/AnimatedNumber';
import { Description, Number, NumberAccent, StatisticsCopy, StatisticsCopyDesription, StatisticsCopyHeading, StatisticsNumber, StatisticsNumbersWrapper, StatisticsSection } from './Statistics.styles';
export const Statistics = ({
  landingPageStatistics
}: {
  landingPageStatistics: ILandingPageStatisticsDto | null;
}) => {
  const {
    t
  } = useTranslation();
  const [statistics, setStatistics] = useState(landingPageStatistics);
  const {
    ref: skinsRef,
    inView: skinsInView
  } = useInView({
    threshold: 1
  });
  const {
    ref: usersRef,
    inView: usersInView
  } = useInView({
    threshold: 1
  });
  const [activeUsersNumber, setActiveUsersNumber] = useState(0);
  const [skinsNumber, setSkinsNumber] = useState(0);
  const {
    data
  } = useQuery({
    queryKey: ['Statistics'],
    queryFn: getStatistics,
    enabled: !statistics
  });
  useEffect(() => {
    if (data) {
      setStatistics(data);
    }
  }, [data]);
  useEffect(() => {
    if (usersInView && statistics?.activeUsersNumber) {
      setActiveUsersNumber(statistics?.activeUsersNumber);
    }
  }, [usersInView, statistics?.activeUsersNumber]);
  useEffect(() => {
    if (skinsInView && statistics?.skinNumber) {
      setSkinsNumber(statistics?.skinNumber);
    }
  }, [skinsInView, statistics?.skinNumber]);
  if (statistics === null) {
    return null;
  }
  return <StatisticsSection data-sentry-element="StatisticsSection" data-sentry-component="Statistics" data-sentry-source-file="Statistics.tsx">
      <StatisticsCopy data-sentry-element="StatisticsCopy" data-sentry-source-file="Statistics.tsx">
        <StatisticsCopyHeading data-sentry-element="StatisticsCopyHeading" data-sentry-source-file="Statistics.tsx">
          <Trans i18nKey="home:statistics.header" data-sentry-element="Trans" data-sentry-source-file="Statistics.tsx">
            In numbers <span>we trust</span>
          </Trans>
        </StatisticsCopyHeading>
        <StatisticsCopyDesription data-sentry-element="StatisticsCopyDesription" data-sentry-source-file="Statistics.tsx">
          {t('home:statistics.description')}
        </StatisticsCopyDesription>
      </StatisticsCopy>

      <StatisticsNumbersWrapper data-sentry-element="StatisticsNumbersWrapper" data-sentry-source-file="Statistics.tsx">
        <StatisticsNumber data-sentry-element="StatisticsNumber" data-sentry-source-file="Statistics.tsx">
          <TimeIcon data-sentry-element="TimeIcon" data-sentry-source-file="Statistics.tsx" />
          <Number data-sentry-element="Number" data-sentry-source-file="Statistics.tsx">
            {statistics.exchangeTime}
            <NumberAccent data-sentry-element="NumberAccent" data-sentry-source-file="Statistics.tsx">S</NumberAccent>
          </Number>
          <Description data-sentry-element="Description" data-sentry-source-file="Statistics.tsx">{t('home:statistics.exchangeTime')}</Description>
        </StatisticsNumber>
        <StatisticsNumber data-sentry-element="StatisticsNumber" data-sentry-source-file="Statistics.tsx">
          <UserIcon data-sentry-element="UserIcon" data-sentry-source-file="Statistics.tsx" />
          <Number ref={usersRef} data-sentry-element="Number" data-sentry-source-file="Statistics.tsx">
            <AnimatedNumber options={{
            duration: 1
          }} value={activeUsersNumber} formatter={latest => (Math.round(latest * 100) / 100).toString()} data-sentry-element="AnimatedNumber" data-sentry-source-file="Statistics.tsx" />
            <NumberAccent data-sentry-element="NumberAccent" data-sentry-source-file="Statistics.tsx">K</NumberAccent>
          </Number>
          <Description data-sentry-element="Description" data-sentry-source-file="Statistics.tsx">{t('home:statistics.activeUsersNumber')}</Description>
        </StatisticsNumber>
        <StatisticsNumber data-sentry-element="StatisticsNumber" data-sentry-source-file="Statistics.tsx">
          <CheckboxIcon data-sentry-element="CheckboxIcon" data-sentry-source-file="Statistics.tsx" />
          <Number ref={skinsRef} data-sentry-element="Number" data-sentry-source-file="Statistics.tsx">
            <AnimatedNumber options={{
            duration: 1
          }} value={skinsNumber} formatter={latest => Math.round(latest).toString()} data-sentry-element="AnimatedNumber" data-sentry-source-file="Statistics.tsx" />
            <NumberAccent data-sentry-element="NumberAccent" data-sentry-source-file="Statistics.tsx">K+</NumberAccent>
          </Number>
          <Description data-sentry-element="Description" data-sentry-source-file="Statistics.tsx">{t('home:statistics.skinNumber')}</Description>
        </StatisticsNumber>
        <StatisticsNumber data-sentry-element="StatisticsNumber" data-sentry-source-file="Statistics.tsx">
          <ChatIcon data-sentry-element="ChatIcon" data-sentry-source-file="Statistics.tsx" />
          <Number data-sentry-element="Number" data-sentry-source-file="Statistics.tsx">
            24<NumberAccent data-sentry-element="NumberAccent" data-sentry-source-file="Statistics.tsx">/7</NumberAccent>
          </Number>
          <Description data-sentry-element="Description" data-sentry-source-file="Statistics.tsx">{t('home:statistics.support')}</Description>
        </StatisticsNumber>
      </StatisticsNumbersWrapper>
    </StatisticsSection>;
};