import { useTranslation } from 'next-i18next';
import { StaticImageData } from 'next/image';
import ArrowIcon from 'public/icons/arrow-right-down-fill.svg';
import GiftIcon from 'public/icons/gift-fill.svg';
import SwapIcon from 'public/icons/swap-fill.svg';
import FlashIcon from 'public/icons/water-flash-fill.svg';
import chickenBgImage from 'public/images/landing/banner-chicken-map-bg.webp';
import freebieWeaponImage from 'public/images/landing/banner-freebies-weapons.webp';
import sellImage from 'public/images/landing/banner-sell.webp';
import tradeImage from 'public/images/landing/banner-trade.webp';
import { useAuth } from 'react-oidc-context';
import { TranslationPaths } from '@/types/i18next';
import { BannerButton, BannerDescription, BannerTitle, BgImageWrapper, FreebieWeaponImageWrapper, IconWrapper, RedirectBannerVariant, RedirectBannerWrapper, StyledBgImage, StyledWeaponImage, WeaponImageWrapper } from './RedirectBanner.styles';
export interface RedirectBannerProps {
  variant: RedirectBannerVariant;
}
const contentMap: Record<RedirectBannerVariant, Record<'title' | 'description' | 'weaponAlt' | 'bgAlt', string>> = {
  trade: {
    title: 'banners.trade.title',
    description: 'banners.trade.description',
    weaponAlt: 'banners.trade.weaponsAlt',
    bgAlt: 'banners.trade.chickenAlt'
  },
  sell: {
    title: 'banners.sell.title',
    description: 'banners.sell.description',
    weaponAlt: 'banners.sell.weaponsAlt',
    bgAlt: 'banners.sell.chickenAlt'
  },
  freebie: {
    title: 'banners.freebie.title',
    description: 'banners.freebie.description',
    weaponAlt: 'banners.trade.weaponsAlt',
    bgAlt: 'banners.trade.chickenAlt'
  }
};
const iconMap: Record<RedirectBannerVariant, React.ReactNode> = {
  trade: <SwapIcon style={{
    transform: 'rotate(90deg)'
  }} />,
  sell: <FlashIcon />,
  freebie: <GiftIcon />
};
const imageMap: Record<'trade' | 'sell' | 'freebie', StaticImageData> = {
  trade: tradeImage,
  sell: sellImage,
  freebie: freebieWeaponImage
};
export const RedirectBanner = ({
  variant
}: RedirectBannerProps) => {
  const {
    t
  } = useTranslation('home');
  const auth = useAuth();
  return <RedirectBannerWrapper $variant={variant} onClick={() => auth.signinRedirect()} data-sentry-element="RedirectBannerWrapper" data-sentry-component="RedirectBanner" data-sentry-source-file="RedirectBanner.tsx">
      <IconWrapper data-sentry-element="IconWrapper" data-sentry-source-file="RedirectBanner.tsx">
        {iconMap[variant]}
        {Array.from({
        length: 8
      }).map((_, i) => <span key={i} />)}
      </IconWrapper>
      <BannerTitle $variant={variant} data-sentry-element="BannerTitle" data-sentry-source-file="RedirectBanner.tsx">
        {t(contentMap[variant].title as TranslationPaths<'home'>)}
      </BannerTitle>
      <BannerDescription data-sentry-element="BannerDescription" data-sentry-source-file="RedirectBanner.tsx">
        {t(contentMap[variant].description as TranslationPaths<'home'>)}
      </BannerDescription>
      <BannerButton onClick={() => auth.signinRedirect()} aria-label={t('banners.signInButtonAriaLabel')} data-sentry-element="BannerButton" data-sentry-source-file="RedirectBanner.tsx">
        <ArrowIcon data-sentry-element="ArrowIcon" data-sentry-source-file="RedirectBanner.tsx" />
      </BannerButton>
      <BgImageWrapper data-sentry-element="BgImageWrapper" data-sentry-source-file="RedirectBanner.tsx">
        <StyledBgImage src={chickenBgImage} alt="" fill style={{
        objectFit: 'cover'
      }} sizes="(max-width: 768px) 356px, 520px" quality={75} data-sentry-element="StyledBgImage" data-sentry-source-file="RedirectBanner.tsx" />
      </BgImageWrapper>
      {variant !== 'freebie' && <WeaponImageWrapper>
          <StyledWeaponImage src={imageMap[variant]} alt={t(contentMap[variant].weaponAlt as TranslationPaths<'home'>)} fill style={{
        objectFit: 'cover'
      }} $delay={variant === 'trade' ? 0 : 1} sizes="(max-width: 992px) 90vw, (max-width: 1700px) 40vw, 788px" quality={80} priority />
        </WeaponImageWrapper>}
      {variant === 'freebie' && <FreebieWeaponImageWrapper>
          <StyledWeaponImage $delay={2} src={imageMap[variant]} alt={t(contentMap[variant].weaponAlt as TranslationPaths<'home'>)} style={{
        objectFit: 'contain',
        objectPosition: 'right',
        height: '100%',
        width: 'auto',
        left: '30%',
        position: 'absolute'
      }} sizes="(max-width: 992px) 90vw, (max-width: 1700px) 40vw, 788px" quality={100} priority />
        </FreebieWeaponImageWrapper>}
    </RedirectBannerWrapper>;
};