import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'

export const TrustedByWrapper = styled.section`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 60px;
    row-gap: 60px;
  }
`
