import Link from 'next/link'
import styled, { css } from 'styled-components'

import { Button } from '@/components/Button'
import Image from '@/components/Image'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, ORANGE, WHITE } from '@/styles/colors'
import { montserrat } from '@/utils/fonts'
import { addAlpha } from '@/utils/styles'

import ChevronRight from '/public/icons/common/chevronRight.svg'
import LongArrow from '/public/icons/common/longArrow.svg'

export type BlogPostCardVariant =
  | 'tile'
  | 'listItem'
  | 'bigTile'
  | 'big'
  | 'medium'

interface VariantProps {
  variant: BlogPostCardVariant
}

export const Chevron = styled(ChevronRight)<{ side: 'left' | 'right' }>`
  color: ${WHITE};
  height: 16px;
  width: auto;
  transform: rotate(${({ side }) => (side === 'left' ? '180deg' : '0deg')});
  color: ${ORANGE.x500};
  transition: transform 0.2s ease-in-out;
`

export const PostWrapper = styled(Link)<VariantProps>`
  display: flex;
  flex-direction: ${({ variant }) =>
    variant === 'listItem' ? 'row' : 'column'};
  column-gap: 16px;
  width: 100%;
  color: ${WHITE};
  background: ${GRAY.x900};
  border-radius: 6px;
  text-decoration: none;
  transition: filter 0.2s;

  ${({ variant }) =>
    variant === 'big' &&
    css`
      height: 464px;
    `}
  ${({ variant }) =>
    variant === 'medium' &&
    css`
      height: 400px;
    `}

  &:hover {
    filter: brightness(1.15);
  }
`

export const PostImageWrapper = styled.div<VariantProps>`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 6px;

  ${({ variant }) =>
    variant === 'medium' &&
    css`
      height: 208px;
    `}

  ${({ variant }) =>
    variant === 'big' &&
    css`
      height: 280px;
    `}
`

export const PostImage = styled(Image)<{ variant?: BlogPostCardVariant }>`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  aspect-ratio: ${({ variant }) =>
    variant === 'listItem' ? '1 / 1' : '16 / 9'};
  object-fit: cover;
  // Object position should change whern the images are prepared for the blog redesign
  object-position: 5% 50%;
`

export const PostBody = styled.div<VariantProps>`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 22px;
  flex-grow: 1;
  ${({ variant }) =>
    variant === 'listItem' &&
    css`
      padding: 10px;
    `}
`

export const PostMeta = styled.div<VariantProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  text-align: left;

  border-bottom: 1px solid ${addAlpha(GRAY.x800, 0.2)};
  padding-bottom: 16px;
`

export const PostLabel = styled.h2<VariantProps>`
  font-family: ${montserrat.style.fontFamily};
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: ${({ variant }) => (variant === 'tile' ? 2 : 'none')};
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const PostContent = styled.p<VariantProps>`
  font-family: ${montserrat.style.fontFamily};
  color: ${GRAY.x400};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  height: auto;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const PostSeparator = styled.div`
  display: none;
  height: 1px;
  width: 100%;
  background-color: rgba(255, 233, 201, 0.2);

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: block;
  }
`

export const PostFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`

export const PostDate = styled.p`
  color: ${GRAY.x400};
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: ${montserrat.style.fontFamily};
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
`

export const PostButton = styled(Button)`
  white-space: nowrap;
`

export const Arrow = styled(LongArrow)`
  margin-left: 16px;
  color: ${ORANGE.x500};
  width: 80px;
  height: auto;
`

export const CarouselArrow = styled.div<{ side: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 40px;
  width: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 50%;
  ${({ side }) => (side === 'left' ? 'left' : 'right')}: 12px;
  background-color: #2c2c2c;
  cursor: pointer;
`

export const ShowOnLargeScreen = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: block;
  }
`

export const ShowOnSmallScreen = styled.div`
  display: block;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: none;
  }
`
