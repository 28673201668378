import { useTranslation } from 'next-i18next';
import { PostsResponse } from '@/api/types/cms';
import { Button } from '@/components/Button';
import { BlogpostsCarousel } from '@/domains/blog/BlogpostsCarousel';
import { getStrapiImagePath } from '@/utils/getStrapiImagePath';
import { urls } from '@/utils/urls';
import { BlogPostsSection, BlogpostsContainer } from './LatestBlogposts.styles';
import { SectionTitle } from '../landing.styles';
export interface LatestBlogpostsProps {
  items: PostsResponse | null;
}
export const LatestBlogposts = ({
  items
}: LatestBlogpostsProps) => {
  const {
    t
  } = useTranslation('home');
  if (!items || items.data.length === 0) return null;
  return <BlogPostsSection data-sentry-element="BlogPostsSection" data-sentry-component="LatestBlogposts" data-sentry-source-file="LatestBlogposts.tsx">
      <BlogpostsContainer data-sentry-element="BlogpostsContainer" data-sentry-source-file="LatestBlogposts.tsx">
        <SectionTitle data-sentry-element="SectionTitle" data-sentry-source-file="LatestBlogposts.tsx">{t('latestBlogposts')}</SectionTitle>
        <BlogpostsCarousel posts={items.data.map(post => ({
        title: post.attributes.title,
        description: post.attributes.excerpt,
        image: getStrapiImagePath(post.attributes.cover.data?.attributes.url || ''),
        imageAlt: post.attributes.cover.data?.attributes.alternativeText || '',
        path: `${urls.blog}/article/${post.attributes.slug}`,
        publishedAt: new Date(post.attributes.publishedAt)
      }))} data-sentry-element="BlogpostsCarousel" data-sentry-source-file="LatestBlogposts.tsx" />
        <Button as="a" href={urls.blog} size="md" variant="gray-fill" data-sentry-element="Button" data-sentry-source-file="LatestBlogposts.tsx">
          {t('checkBlogPosts')}
        </Button>
      </BlogpostsContainer>
    </BlogPostsSection>;
};