import { format } from 'date-fns'
import { FormatOptions } from 'date-fns/format'
import { de, enGB, es, mn, pl, pt, tr, vi } from 'date-fns/locale'
import { Locale } from 'next/router'

export const getDateFnsLocale = (language: Locale) => {
  switch (language) {
    case 'en':
      return enGB
    case 'pl':
      return pl
    case 'es':
      return es
    case 'tr':
      return tr
    case 'pt':
      return pt
    case 'de':
      return de
    case 'vi':
      return vi
    case 'mn':
      return mn
    default:
      return enGB
  }
}

export const getFormattedDate = (
  date: number | string | Date | undefined | null,
  dateFormat = 'dd.MM.yyyy',
  locale?: Locale,
  options?: FormatOptions
): string => {
  if (!date) {
    return '-'
  }

  const formatLocale = getDateFnsLocale(locale || 'en')

  return format(new Date(date), dateFormat, {
    ...options,
    locale: formatLocale,
  })
}
