import styled from 'styled-components'

import { commonButton } from '@/components/Button/Button.styles'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GOLD, GRAY, GREEN, WHITE } from '@/styles/colors'
import { goldman, lato } from '@/utils/fonts'
import { hexToRgb } from '@/utils/style/hexToRgb'

export const HeroWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const HeroContent = styled.div`
  margin-top: 112px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  z-index: 2;
  text-align: center;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 100px;
    margin-bottom: 64px;
    row-gap: 44px;
    text-align: start;
  }
`

export const HeroTitle = styled.h1`
  margin: 0 auto;
  font-family: ${goldman.style.fontFamily};
  font-size: 40px;
  font-weight: 700;
  line-height: 36px;
  text-transform: uppercase;
  color: ${WHITE};
  text-wrap: balance;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  max-width: 20ch;

  & > span {
    color: ${GOLD.x300};
  }

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 60px;
    line-height: 50px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin: 0;
    font-size: 80px;
    line-height: 70px;
  }
`

export const HeroDescription = styled.p`
  margin: 0 auto;
  font-family: ${lato.style.fontFamily};
  font-size: 14px;
  font-weight: 700;
  color: ${GRAY.x400};
  text-wrap: balance;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  max-width: 50ch;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 18px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin: 0;
    font-size: 20px;
  }
`

export const HeroButton = styled.button`
  ${commonButton};
  position: relative;
  margin: 8px;
  width: calc(100% - 16px);
  display: inline;
  padding: 16px 24px;
  background-color: #1b3109;
  outline: 1px solid #9bff49;
  outline-offset: 7px;
  border-radius: 6px;

  font-family: ${goldman.style.fontFamily};
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${WHITE};
  & > span {
    color: ${GREEN.x400};
  }

  &:hover,
  &:focus-visible {
    background-color: ${GREEN.x900};
    outline: 1px solid ${GREEN.x400};
    outline-offset: 0px;
    img {
      transform: scale(0.98);
    }
  }
  &:active {
    background-color: ${GREEN.x950};
    outline: 1px solid ${GREEN.x600};
    outline-offset: 0px;
  }

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    width: max-content;
    margin: 8px auto;
  }
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    padding: 18px 36px;
    font-size: 20px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    padding: 20px 48px;
    font-size: 24px;
    margin: 8px;
  }

  & > img {
    transition: all 0.3s;
    position: absolute;
    left: -2.5%;
    bottom: -38px;
    width: 105%;
  }
`

export const HeroImageWrapper = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 294px;
  height: 294px;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 80%,
      rgba(${hexToRgb('#141414')}, 0.99) 90%,
      rgba(${hexToRgb('#141414')}, 1) 100%
    );
  }

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    width: 820px;
    height: 769px;
    top: 0;
    right: -64px;
    left: unset;
    transform: none;
  }
`

export const HeroBackgroundImageWrapper = styled.div`
  position: absolute;
  top: -60px;
  left: -16px;
  right: -16px;
  bottom: 40%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 20%,
      rgba(${hexToRgb('#141414')}, 0.99) 70%,
      rgba(${hexToRgb('#141414')}, 1) 100%
    );
  }

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    bottom: 30%;
  }
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    left: -24px;
    right: -24px;
    bottom: 20%;
  }

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    top: 0;
    right: -124px;
    left: unset;
    bottom: unset;
    width: 100%;
    max-width: 1000px;
    aspect-ratio: 9/7;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      background: radial-gradient(
        ellipse at center,
        rgba(0, 0, 0, 0) 20%,
        rgba(${hexToRgb('#141414')}, 0.99) 70%,
        rgba(${hexToRgb('#141414')}, 1) 100%
      );
    }
  }
`
