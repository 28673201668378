import { ReactNode } from 'react';
import { ProfitTileIconWrapper, ProfitTileText, ProfitTileWrapper } from './ProfitTile.styles';
export interface ProfitTileProps {
  icon: ReactNode;
  text: ReactNode;
}
export const ProfitTile = ({
  icon,
  text
}: ProfitTileProps) => {
  return <ProfitTileWrapper data-sentry-element="ProfitTileWrapper" data-sentry-component="ProfitTile" data-sentry-source-file="ProfitTile.tsx">
      <ProfitTileIconWrapper data-sentry-element="ProfitTileIconWrapper" data-sentry-source-file="ProfitTile.tsx">{icon}</ProfitTileIconWrapper>
      <ProfitTileText data-sentry-element="ProfitTileText" data-sentry-source-file="ProfitTile.tsx">{text}</ProfitTileText>
    </ProfitTileWrapper>;
};