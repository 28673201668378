import { ILandingPageStatisticsDto } from '@contracts/web';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { ReactElement } from 'react';
import { getHelpCenterArticles, getPosts } from '@/api/cms';
import { HelpCenterArticle, HelpCenterArticlesResponse, PostsResponse } from '@/api/types/cms';
import { useAuthRedirect } from '@/auth/useAuthRedirect';
import { Footer } from '@/components/Footer';
import { ReferalLinkModalContextProvider } from '@/contexts/ReferalLinkModalContext';
import { HelpBanner } from '@/domains/helpCenter/HelpBanner';
import { Banners } from '@/domains/landing/Banners';
import { FeaturedArticles } from '@/domains/landing/FeaturedArticles';
import { FrequentlyReadArticles } from '@/domains/landing/FrequentlyReadArticles';
import { Hero } from '@/domains/landing/Hero';
import { IntroSteps } from '@/domains/landing/IntroSteps';
import { LatestBlogposts } from '@/domains/landing/LatestBlogposts';
import { Statistics } from '@/domains/landing/Statistics';
import { TrustedBy } from '@/domains/landing/TrustedBy';
import { LandingWrapper } from '@/domains/landing/landing.styles';
import { getUniLocale } from '@/utils/getUniLocale';
import { isMaintenanceMode, maintenanceRedirect } from '@/utils/maintenanceRedirect';
interface LandingPagePropsInterface {
  landingPageStatistics: ILandingPageStatisticsDto | null;
  latestBlogposts: PostsResponse | null;
  featuredArticles: HelpCenterArticlesResponse | null;
  frequentlyReadArticles: HelpCenterArticlesResponse | null;
}
const Home = ({
  landingPageStatistics,
  latestBlogposts,
  featuredArticles,
  frequentlyReadArticles
}: LandingPagePropsInterface) => {
  const {
    t
  } = useTranslation('common');
  useAuthRedirect();
  const featuredArticlesData: HelpCenterArticle[] = featuredArticles?.data || [];
  const frequentlyReadArticlesData: HelpCenterArticle[] = frequentlyReadArticles?.data || [];
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <title>{t('title.landing')}</title>
        <meta name="description" content={t('description.landing')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <meta name="keywords" content={t('keywords.landing')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <meta name="og:title" content={t('title.landing')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <meta name="og:description" content={t('description.landing')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <style jsx global>
          {`
            body {
              background-color: #141414 !important;
            }
          `}
        </style>
      </Head>
      <LandingWrapper data-sentry-element="LandingWrapper" data-sentry-source-file="index.tsx">
        <Hero data-sentry-element="Hero" data-sentry-source-file="index.tsx" />
        <Banners data-sentry-element="Banners" data-sentry-source-file="index.tsx" />
        <IntroSteps data-sentry-element="IntroSteps" data-sentry-source-file="index.tsx" />
        <TrustedBy data-sentry-element="TrustedBy" data-sentry-source-file="index.tsx" />
        <LatestBlogposts items={latestBlogposts} data-sentry-element="LatestBlogposts" data-sentry-source-file="index.tsx" />
        <Statistics landingPageStatistics={landingPageStatistics} data-sentry-element="Statistics" data-sentry-source-file="index.tsx" />
        <FeaturedArticles articles={featuredArticlesData} data-sentry-element="FeaturedArticles" data-sentry-source-file="index.tsx" />
        <FrequentlyReadArticles articles={frequentlyReadArticlesData} data-sentry-element="FrequentlyReadArticles" data-sentry-source-file="index.tsx" />
        <HelpBanner data-sentry-element="HelpBanner" data-sentry-source-file="index.tsx" />
        <Footer data-sentry-element="Footer" data-sentry-source-file="index.tsx" />
      </LandingWrapper>
    </>;
};
Home.getLayout = (page: ReactElement) => <ReferalLinkModalContextProvider>{page}</ReferalLinkModalContextProvider>;
export default Home;
export const getStaticProps: GetStaticProps<LandingPagePropsInterface> = async ({
  locale
}) => {
  if (isMaintenanceMode) {
    return maintenanceRedirect();
  }
  let landingPageStatistics: ILandingPageStatisticsDto | null = null;
  let latestBlogposts: PostsResponse | null = null;
  let featuredArticles: HelpCenterArticlesResponse | null = null;
  let frequentlyReadArticles: HelpCenterArticlesResponse | null = null;
  const uniLocale = getUniLocale(locale);
  try {
    latestBlogposts = await getPosts({
      highlighted: true,
      locale: uniLocale,
      pageSize: 3
    });
  } catch (_error) {
    console.error('Error! Failed to fetch latest blogposts');
  }
  try {
    featuredArticles = await getHelpCenterArticles({
      locale: uniLocale,
      isFeatured: true,
      pageSize: 3
    });
    frequentlyReadArticles = await getHelpCenterArticles({
      locale: uniLocale,
      pageSize: 6
    });
  } catch (_error) {
    console.error('Error! Failed to fetch help center articles');
  }
  try {
    const data = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/Statistics`);
    landingPageStatistics = await data.json();
  } catch (_error) {
    console.error('Error! Failed to get landing page statistics');
  }
  return {
    props: {
      landingPageStatistics,
      latestBlogposts,
      featuredArticles,
      frequentlyReadArticles,
      ...(locale ? await serverSideTranslations(locale, ['home', 'common', 'header', 'footer', 'error', 'referalModal', 'settings', 'trustedUs', 'helpCenter']) : {})
    }
  };
};