import { StaticImageData } from 'next/image';
import prizeImage from 'public/images/help-center/prize.webp';
import starsImage from 'public/images/help-center/stars.webp';
import tradeImage from 'public/images/help-center/trade.webp';
import { FeaturedArticleContentWrapper, FeaturedArticleDecoShine, FeaturedArticleDescription, FeaturedArticleImage, FeaturedArticleImageWrapper, FeaturedArticleTitle, FeaturedArticleWrapper } from './FeaturedArticle.styles';
export interface FeaturedArticleProps {
  title: string;
  description: string;
  image: StaticImageData;
  href: string;
}
export const FeaturedArticle = ({
  title,
  description,
  image,
  href
}: FeaturedArticleProps) => {
  return <FeaturedArticleWrapper href={href} data-sentry-element="FeaturedArticleWrapper" data-sentry-component="FeaturedArticle" data-sentry-source-file="FeaturedArticle.tsx">
      <FeaturedArticleDecoShine data-sentry-element="FeaturedArticleDecoShine" data-sentry-source-file="FeaturedArticle.tsx" />
      <FeaturedArticleImageWrapper data-sentry-element="FeaturedArticleImageWrapper" data-sentry-source-file="FeaturedArticle.tsx">
        <FeaturedArticleImage src={image} alt={title} fill draggable={false} data-sentry-element="FeaturedArticleImage" data-sentry-source-file="FeaturedArticle.tsx" />
      </FeaturedArticleImageWrapper>
      <FeaturedArticleContentWrapper data-sentry-element="FeaturedArticleContentWrapper" data-sentry-source-file="FeaturedArticle.tsx">
        <FeaturedArticleTitle data-sentry-element="FeaturedArticleTitle" data-sentry-source-file="FeaturedArticle.tsx">{title}</FeaturedArticleTitle>
        <FeaturedArticleDescription data-sentry-element="FeaturedArticleDescription" data-sentry-source-file="FeaturedArticle.tsx">{description}</FeaturedArticleDescription>
      </FeaturedArticleContentWrapper>
    </FeaturedArticleWrapper>;
};
export const featuredArticlesImages = [{
  image: starsImage
}, {
  image: tradeImage
}, {
  image: prizeImage
}];