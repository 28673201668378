import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GOLD, LANDING_GRAY, ORANGE, WHITE } from '@/styles/colors'
import { goldman } from '@/utils/fonts'

export const ProfitTileWrapper = styled.div`
  padding: 36px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
  background-color: ${LANDING_GRAY};
  border-radius: 6px;
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    &:nth-of-type(4) {
      grid-column: span 2;
    }
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    padding: 48px;
    &:nth-of-type(4) {
      grid-column: span 1;
    }
  }
`

export const ProfitTileIconWrapper = styled.div`
  & > svg {
    width: 64px;
    height: 64px;
    color: ${ORANGE.x500};
  }
`

export const ProfitTileText = styled.p`
  font-family: ${goldman.style.fontFamily};
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${WHITE};
  text-wrap: balance;
  max-width: 50%;

  & > span {
    color: ${GOLD.x300};
  }

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    font-size: 18px;
  }

  @media (min-width: ${BREAKPOINTS_PX.xxl}) {
    font-size: 20px;
  }
`
