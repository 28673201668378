import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'

export const BlogpostsContent = styled.div`
  column-gap: 24px;
  display: none;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: flex;
  }
`

export const StyledCarousel = styled(Carousel)`
  width: 100%;
  position: relative;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: none;
  }
`
