import Image from 'next/image'
import styled from 'styled-components'

import { Button } from '@/components/Button'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { BLUE, GRAY, WHITE } from '@/styles/colors'
import { montserrat } from '@/utils/fonts'

export const HelpBannerWrapper = styled.div`
  container-type: inline-size;
  position: relative;
  margin-top: 12px;
  padding: 52px 24px;
  display: grid;
  place-items: center;
  gap: 20px;
  background: linear-gradient(133deg, #00101a 24.19%, #004f80 225.58%);
  border-radius: 6px;
  overflow: hidden;

  --max-width: 432px;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 36px;
  }
`

export const HelpBannerTitle = styled.h5`
  text-align: center;
  font-family: ${montserrat.style.fontFamily};
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: ${WHITE};
  max-width: var(--max-width);
  z-index: 1;
`

export const HelpBannerDescription = styled.p`
  text-align: center;
  font-family: ${montserrat.style.fontFamily};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${GRAY.x400};
  text-wrap: balance;
  max-width: var(--max-width);
  z-index: 1;
`

export const HelpBannerButton = styled(Button)`
  margin-top: 4px;
  z-index: 1;

  color: ${GRAY.x900};
  background-color: ${BLUE.x400};

  &:hover {
    background-color: ${BLUE.x600};
  }

  &:active {
    background-color: ${BLUE.x700};
    color: ${GRAY.x900};
    border-color: transparent;
  }
`

export const HelpBannerChickenDeco = styled(Image)`
  display: none;
  position: absolute;
  top: 0;
  left: -120px;
  height: 100%;
  width: auto;

  @container (min-width: ${BREAKPOINTS_PX.md}) {
    display: block;
  }
  @container (min-width: ${BREAKPOINTS_PX.lg}) {
    left: -40px;
  }
  @container (min-width: ${BREAKPOINTS_PX.xl}) {
    left: 0;
  }
`

export const HelpBannerGunsDeco = styled(Image)`
  display: none;
  position: absolute;
  top: 0;
  right: -200px;
  height: 100%;
  width: auto;

  @container (min-width: ${BREAKPOINTS_PX.md}) {
    display: block;
  }
  @container (min-width: ${BREAKPOINTS_PX.lg}) {
    right: -100px;
  }
  @container (min-width: ${BREAKPOINTS_PX.xl}) {
    right: 0;
  }
`
