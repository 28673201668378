import styled, { css } from 'styled-components'

import { commonButton } from '@/components/Button/Button.styles'
import Image from '@/components/Image'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { BLACK, BLUE, GRAY, GREEN, VIOLET, WHITE } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { goldman, lato } from '@/utils/fonts'
import { hexToRgb } from '@/utils/style/hexToRgb'

import { floatAnimation } from '../../landing.styles'

export type RedirectBannerVariant = 'trade' | 'sell' | 'freebie'

const variantColorMap: Record<
  RedirectBannerVariant,
  Record<'color' | 'background', string>
> = {
  trade: {
    background: GREEN.x500,
    color: BLACK,
  },
  sell: {
    background: VIOLET.x600,
    color: WHITE,
  },
  freebie: {
    background: BLUE.x500,
    color: BLACK,
  },
}

export const RedirectBannerWrapper = styled.div<{
  $variant: RedirectBannerVariant
}>`
  position: relative;
  padding: 24px 24px 60px 24px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  background-color: ${({ $variant }) => variantColorMap[$variant].background};
  color: ${({ $variant }) => variantColorMap[$variant].color};
  border-radius: 6px;
  overflow: hidden;
  transition: filter 0.2s;
  cursor: pointer;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    row-gap: 20px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    row-gap: 28px;
    padding-left: 48px;
  }

  ${({ $variant }) =>
    $variant === 'freebie' &&
    css`
      grid-column: 1 / -1;
    `}

  &:hover {
    filter: saturate(1.1) contrast(1.1);
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: inherit;

  & > svg {
    height: 32px;
    width: 32px;
  }

  & > span {
    display: none;
    width: 2px;
    height: 14px;
    background-color: currentColor;
  }

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    & > span {
      display: block;
    }
  }
`

export const BannerTitle = styled.h2<{ $variant?: RedirectBannerVariant }>`
  font-family: ${goldman.style.fontFamily};
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: inherit;
  z-index: 1;
  max-width: 8ch;
  text-shadow: 0 1px 2px rgba(${hexToRgb(GRAY.x800)}, 0.5);

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    max-width: ${({ $variant }) => ($variant === 'freebie' ? '24ch' : '15ch')};
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 30px;
    line-height: 30px;
  }
`

export const BannerDescription = styled.p`
  display: none;
  font-family: ${lato.style.fontFamily};
  font-size: 14px;
  font-weight: 700;
  color: inherit;
  width: 40%;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    display: inline;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 18px;
  }
`

export const BannerButton = styled.button`
  ${commonButton}
  position: absolute;
  right: 12px;
  bottom: 12px;
  width: 46px;
  aspect-ratio: 1/1;
  border-radius: 4px;
  background-color: ${BLACK};
  color: ${WHITE};
  z-index: 1;
  transition: background-color ${TIMINGS.x01};

  & > svg {
    width: 18px;
    height: 18px;
  }

  &:hover {
    background-color: ${GRAY.x950};
  }
`

export const WeaponImageWrapper = styled.div`
  position: absolute;
  top: -15%;
  right: 0;
  height: 130%;
  width: 100%;
`

export const FreebieWeaponImageWrapper = styled.div`
  position: absolute;
  top: -2%;
  right: 150px;
  height: 120%;
  width: 50%;
  transition: right 0.3s ease-in-out;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    right: 0;
    width: 80%;
  }
  @media (min-width: ${BREAKPOINTS_PX.xxl}) {
    right: -100px;
  }
`

export const StyledWeaponImage = styled(Image)<{ $delay: number }>`
  ${floatAnimation}
  animation-delay: ${({ $delay }) => -$delay}s;
`

export const BgImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  aspect-ratio: 2/1;
`

export const StyledBgImage = styled(Image)`
  opacity: 1;
  mix-blend-mode: multiply;
`
