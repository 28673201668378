import { HelpCenterArticle } from '@/api/types/cms';
import { FeaturedArticle } from '@/domains/helpCenter/FeaturedArticle';
import { featuredArticlesImages } from '@/domains/helpCenter/FeaturedArticle/FeaturedArticle';
import { generateHelpCenterUrl } from '@/utils/urls';
import { FeaturedArticlesList, FeaturedArticlesWrapper } from './FeaturedArticles.styles';
import { SectionTitle } from '../landing.styles';
export interface FeaturedArticlesProps {
  articles: HelpCenterArticle[];
}
export const FeaturedArticles = ({
  articles
}: FeaturedArticlesProps) => {
  return <FeaturedArticlesWrapper data-sentry-element="FeaturedArticlesWrapper" data-sentry-component="FeaturedArticles" data-sentry-source-file="FeaturedArticles.tsx">
      <SectionTitle data-sentry-element="SectionTitle" data-sentry-source-file="FeaturedArticles.tsx">Featured Articles</SectionTitle>
      <FeaturedArticlesList data-sentry-element="FeaturedArticlesList" data-sentry-source-file="FeaturedArticles.tsx">
        {articles.map((article, idx) => <FeaturedArticle key={article.id} description={article.attributes.description} title={article.attributes.title} href={generateHelpCenterUrl({
        categoryName: article.attributes.category.data.attributes.name,
        articleSlug: article.attributes.slug
      })} image={featuredArticlesImages[idx].image} />)}
      </FeaturedArticlesList>
    </FeaturedArticlesWrapper>;
};