import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, WHITE } from '@/styles/colors'
import { goldman } from '@/utils/fonts'

export const FrequentlyReadArticlesWrapper = styled.section`
  grid-column: 1 / -1;
  margin-top: 24px;
  display: grid;
  grid-template-columns: subgrid;
  gap: 16px;

  background-color: ${GRAY.x950};

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    gap: 24px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 60px;
    gap: 60px;
  }
`

export const FrequentlyReadArticlesTitle = styled.h3`
  grid-column: 2;
  margin-top: 24px;
  width: 100%;
  padding: 0 20px;
  font-family: ${goldman.style.fontFamily};
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  text-wrap: balance;
  color: ${WHITE};

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 18px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 60px;
    font-size: 20px;
  }
`

export const FrequentlyReadArticlesList = styled.div`
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  padding-bottom: 24px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    padding-bottom: 60px;
  }
`
