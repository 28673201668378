import CheckIcon from 'public/icons/checkbox-circle-fill.svg';
import { StepDescription, StepTitle, StepWrapper, StepsColumn } from './Steps.styles';
export interface StepProps {
  title: string;
  description: string;
}
export interface StepsProps {
  items: StepProps[];
}
export const Steps = ({
  items
}: StepsProps) => {
  return <StepsColumn data-sentry-element="StepsColumn" data-sentry-component="Steps" data-sentry-source-file="Steps.tsx">
      {items.map(({
      description,
      title
    }, idx) => <StepWrapper key={idx}>
          <CheckIcon />
          <span />
          <StepTitle>{title}</StepTitle>
          <StepDescription>{description}</StepDescription>
        </StepWrapper>)}
    </StepsColumn>;
};