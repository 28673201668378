import { useTranslation } from 'next-i18next';
import ArrowIcon from 'public/icons/arrow-right-down-fill.svg';
import BlikIcon from 'public/icons/payout-methods/blik.svg';
import CryptoIcon from 'public/icons/payout-methods/crypto.svg';
import PayPalIcon from 'public/icons/payout-methods/paypal.svg';
import SkrillIcon from 'public/icons/payout-methods/skrill.svg';
import VisaIcon from 'public/icons/payout-methods/visa.svg';
import weaponImg from 'public/images/landing/banner-quick-cash.webp';
import { useAuth } from 'react-oidc-context';
import { HideOnBiggerThan } from '@/components/HideOn';
import { ImageBgBars, PayoutMethods, QuickCashBannerDescription, QuickCashBannerTitle, QuickCashBannerWrapper, StyledWeaponImage, WeaponImageWrapper } from './QuickCashBanner.styles';
import { BannerButton } from './RedirectBanner.styles';
export const QuickCashBanner = () => {
  const {
    t
  } = useTranslation('home');
  const auth = useAuth();
  return <QuickCashBannerWrapper data-sentry-element="QuickCashBannerWrapper" data-sentry-component="QuickCashBanner" data-sentry-source-file="QuickCashBanner.tsx">
      <QuickCashBannerTitle data-sentry-element="QuickCashBannerTitle" data-sentry-source-file="QuickCashBanner.tsx">{t('banners.cash.title')}</QuickCashBannerTitle>
      <QuickCashBannerDescription data-sentry-element="QuickCashBannerDescription" data-sentry-source-file="QuickCashBanner.tsx">
        {t('banners.cash.description')}
      </QuickCashBannerDescription>
      <PayoutMethods data-sentry-element="PayoutMethods" data-sentry-source-file="QuickCashBanner.tsx">
        <VisaIcon data-sentry-element="VisaIcon" data-sentry-source-file="QuickCashBanner.tsx" />
        <BlikIcon data-sentry-element="BlikIcon" data-sentry-source-file="QuickCashBanner.tsx" />
        <PayPalIcon data-sentry-element="PayPalIcon" data-sentry-source-file="QuickCashBanner.tsx" />
        <SkrillIcon data-sentry-element="SkrillIcon" data-sentry-source-file="QuickCashBanner.tsx" />
        <CryptoIcon data-sentry-element="CryptoIcon" data-sentry-source-file="QuickCashBanner.tsx" />
      </PayoutMethods>
      <WeaponImageWrapper data-sentry-element="WeaponImageWrapper" data-sentry-source-file="QuickCashBanner.tsx">
        <StyledWeaponImage src={weaponImg} alt={t('banners.cash.weaponAlt')} fill style={{
        objectFit: 'cover'
      }} sizes="(max-width: 768px) 70vw, 720px" quality={80} data-sentry-element="StyledWeaponImage" data-sentry-source-file="QuickCashBanner.tsx" />
        <ImageBgBars data-sentry-element="ImageBgBars" data-sentry-source-file="QuickCashBanner.tsx">
          {Array.from({
          length: 14
        }).map((_, i) => <span key={i} />)}
        </ImageBgBars>
      </WeaponImageWrapper>
      <HideOnBiggerThan breakpoint="lg" data-sentry-element="HideOnBiggerThan" data-sentry-source-file="QuickCashBanner.tsx">
        <BannerButton onClick={() => auth.signinRedirect()} aria-label={t('banners.signInButtonAriaLabel')} data-sentry-element="BannerButton" data-sentry-source-file="QuickCashBanner.tsx">
          <ArrowIcon data-sentry-element="ArrowIcon" data-sentry-source-file="QuickCashBanner.tsx" />
        </BannerButton>
      </HideOnBiggerThan>
    </QuickCashBannerWrapper>;
};