import { HelpCenterArticle } from '@/api/types/cms';
import { ArticleButton } from '@/domains/helpCenter/ArticleButton';
import { generateHelpCenterUrl } from '@/utils/urls';
import { FrequentlyReadArticlesList, FrequentlyReadArticlesTitle, FrequentlyReadArticlesWrapper } from './FrequentlyReadArticles.styles';
export interface FrequentlyReadArticlesProps {
  articles: HelpCenterArticle[];
}
export const FrequentlyReadArticles = ({
  articles
}: FrequentlyReadArticlesProps) => {
  return <FrequentlyReadArticlesWrapper data-sentry-element="FrequentlyReadArticlesWrapper" data-sentry-component="FrequentlyReadArticles" data-sentry-source-file="FrequentlyReadArticles.tsx">
      <FrequentlyReadArticlesTitle data-sentry-element="FrequentlyReadArticlesTitle" data-sentry-source-file="FrequentlyReadArticles.tsx">
        Frequently Read Articles
      </FrequentlyReadArticlesTitle>
      <FrequentlyReadArticlesList data-sentry-element="FrequentlyReadArticlesList" data-sentry-source-file="FrequentlyReadArticles.tsx">
        {articles.map(article => <ArticleButton key={article.id} href={generateHelpCenterUrl({
        categoryName: article.attributes.category.data.attributes.name,
        articleSlug: article.attributes.slug
      })} title={article.attributes.title} />)}
      </FrequentlyReadArticlesList>
    </FrequentlyReadArticlesWrapper>;
};