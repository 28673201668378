import { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BlogpostsContent, StyledCarousel } from './BlogpostsCarousel.styles';
import { BlogPostCard, BlogPostCardProps } from '../BlogPostCard';
export interface BlogpostsCarouselProps {
  posts: BlogPostCardProps[];
}
export const BlogpostsCarousel = ({
  posts
}: BlogpostsCarouselProps) => {
  const [currentIdx, setCurrentIdx] = useState(0);
  return <>
      <BlogpostsContent data-sentry-element="BlogpostsContent" data-sentry-source-file="BlogpostsCarousel.tsx">
        {posts.map((post, index) => <BlogPostCard key={index} {...post} />)}
      </BlogpostsContent>
      <StyledCarousel selectedItem={currentIdx} showIndicators={false} showThumbs={false} preventMovementUntilSwipeScrollTolerance showStatus={false} axis="horizontal" showArrows={false} onChange={idx => setCurrentIdx(idx)} renderItem={item => <div style={{
      margin: '0 24px'
    }}>{item}</div>} data-sentry-element="StyledCarousel" data-sentry-source-file="BlogpostsCarousel.tsx">
        {posts.map((post, index) => <BlogPostCard key={index} {...post} showArrows onPrevClick={() => setCurrentIdx(currentIdx > 0 ? currentIdx - 1 : 2)} onNextClick={() => setCurrentIdx(currentIdx < 2 ? currentIdx + 1 : 0)} />)}
      </StyledCarousel>
    </>;
};