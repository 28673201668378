import styled from 'styled-components'

import { commonButton } from '@/components/Button'
import Image from '@/components/Image'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { BLACK, BLUE, GRAY, WHITE } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { goldman, lato } from '@/utils/fonts'
import { hexToRgb } from '@/utils/style/hexToRgb'

import { SectionTitle, floatAnimation } from '../landing.styles'

export const IntroStepsWrapper = styled.section`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 60px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 60px;
  }
`

export const IntroStepsTitle = styled(SectionTitle)`
  grid-column: 1/-1;
`

export const MovieWrapper = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    display: grid;
    place-items: center;
    grid-column: 2/-1;
    & > video {
      border-radius: 6px;
      overflow: hidden;
    }
  }
`

export const BestSkinsBanner = styled.div`
  position: relative;
  padding: 36px 24px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  background-color: ${BLUE.x200};
  border-radius: 6px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    grid-column: span 2;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    grid-column: span 1;
    margin-top: -36px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    padding: 48px;
  }
`

export const BestSkinsTitle = styled.h3`
  max-width: 10ch;
  margin: 0 auto;
  font-family: ${goldman.style.fontFamily};
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  text-wrap: balance;
  line-height: 30px;
  color: ${BLACK};

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 30px;
  }

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    max-width: 80%;
    margin: 0;
    text-align: start;
  }
`

export const BannerText = styled.p`
  display: none;
  max-width: 80%;
  font-family: ${lato.style.fontFamily};
  font-size: 18px;
  font-weight: 700;
  text-wrap: balance;
  line-height: 26px;
  color: ${BLACK};

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    display: inline;
  }
`

export const BestSkinsButton = styled.button`
  ${commonButton}
  position: absolute;
  right: 12px;
  bottom: 12px;
  width: 34px;
  aspect-ratio: 1/1;
  border-radius: 4px;
  background-color: ${BLACK};
  color: ${WHITE};
  transition: background-color ${TIMINGS.x01};

  & > svg {
    width: 18px;
    height: 18px;
  }

  &:hover {
    background-color: ${GRAY.x950};
  }

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    right: 24px;
    bottom: 24px;
    width: 46px;
  }
`

export const GetBonusBanner = styled.div`
  position: relative;
  padding: 48px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 28px;
  background-color: ${BLUE.x500};
  border-radius: 6px;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    grid-column: span 2;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    grid-column: 2/-1;
    margin-top: -36px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    padding: 48px 48px 24px;
    row-gap: 20px;
  }
`

export const GetBonusTitle = styled.h3`
  font-family: ${goldman.style.fontFamily};
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${BLACK};
  max-width: 15ch;
  z-index: 1;
  text-shadow: 0 1px 2px rgba(${hexToRgb(GRAY.x800)}, 0.5);

  & > span {
    font-size: 28px;
    color: ${WHITE};
  }
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 40px;
    & > span {
      font-size: 50px;
    }
  }
`

export const GetBonusText = styled(BannerText)`
  max-width: 40%;
  z-index: 1;
`

export const GetBonusButton = styled.button`
  ${commonButton}
  width: fit-content;
  height: 34px;
  padding: 12px 28px;
  font-family: ${lato.style.fontFamily};
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${BLACK};
  background-color: ${WHITE};
  border-radius: 4px;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    height: 46px;
    padding: 20px 40px;
    font-size: 14px;
  }
`

export const GetBonusWeapon = styled.div`
  position: absolute;
  right: -10%;
  top: -25%;
  height: 150%;
  width: 100%;
`

export const GetBonusWeaponImage = styled(Image)`
  ${floatAnimation}
  rotate: 5deg;
`
