import { Trans, useTranslation } from 'next-i18next';
import heroBgSm from 'public/images/landing/hero-bg-sm.webp';
import heroBg from 'public/images/landing/hero-bg.webp';
import heroChicken from 'public/images/landing/hero-chicken.webp';
import { useAuth } from 'react-oidc-context';
import Image from '@/components/Image';
import { useWindowSize } from '@/hooks/useWindowSize';
import { HeroBackgroundImageWrapper, HeroButton, HeroContent, HeroDescription, HeroImageWrapper, HeroTitle, HeroWrapper } from './Hero.styles';
export const Hero = () => {
  const {
    t
  } = useTranslation('home');
  const {
    isLargeScreenWidth
  } = useWindowSize();
  const auth = useAuth();
  return <HeroWrapper data-sentry-element="HeroWrapper" data-sentry-component="Hero" data-sentry-source-file="Hero.tsx">
      <HeroBackgroundImageWrapper data-sentry-element="HeroBackgroundImageWrapper" data-sentry-source-file="Hero.tsx">
        {!isLargeScreenWidth && <Image src={heroBgSm} alt={t('hero.backgroundAlt')} fill style={{
        objectFit: 'cover'
      }} sizes="(max-width: 992px) 100vw, 1000px" priority quality={90} />}
        {isLargeScreenWidth && <Image src={heroBg} alt={t('hero.backgroundAlt')} fill style={{
        objectFit: 'cover'
      }} sizes="(max-width: 992px) 100vw, 1000px" priority quality={75} />}
      </HeroBackgroundImageWrapper>

      <HeroContent data-sentry-element="HeroContent" data-sentry-source-file="Hero.tsx">
        <div style={{
        height: '40px'
      }} />
        <HeroTitle data-sentry-element="HeroTitle" data-sentry-source-file="Hero.tsx">
          <Trans ns="home" i18nKey="hero.title" components={{
          color: <span />
        }} data-sentry-element="Trans" data-sentry-source-file="Hero.tsx" />
        </HeroTitle>
        <HeroDescription data-sentry-element="HeroDescription" data-sentry-source-file="Hero.tsx">{t('hero.description')}</HeroDescription>
        <HeroButton onClick={() => auth.signinRedirect()} data-sentry-element="HeroButton" data-sentry-source-file="Hero.tsx">
          <Trans ns="home" i18nKey="hero.button" components={{
          color: <span />
        }} data-sentry-element="Trans" data-sentry-source-file="Hero.tsx" />
        </HeroButton>
      </HeroContent>

      <HeroImageWrapper data-sentry-element="HeroImageWrapper" data-sentry-source-file="Hero.tsx">
        <Image src={heroChicken} alt={t('hero.chickenAlt')} fill priority fetchPriority="high" sizes="(max-width: 992px) 294px, 820px" quality={75} style={{
        objectFit: 'cover'
      }} placeholder="blur" data-sentry-element="Image" data-sentry-source-file="Hero.tsx" />
      </HeroImageWrapper>
    </HeroWrapper>;
};