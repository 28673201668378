import Carousel from 'react-multi-carousel'
import styled from 'styled-components'

import { IconButton } from '@/components/Button'

export const CarouselWrapper = styled.div`
  position: relative;
`

export const StyledCarousel = styled(Carousel)`
  margin: 0 64px;
`

export const ArrowButton = styled(IconButton)<{ $type: 'prev' | 'next' }>`
  position: absolute;
  top: 50%;
  ${({ $type }) => ($type === 'prev' ? 'left: 0;' : 'right: 0;')}
  transform: translateY(-50%);
`
