import { BannersWrapper } from './Banners.styles';
import { RedirectBanner } from './RedirectBanner';
import { QuickCashBanner } from './RedirectBanner/QuickCashBanner';
export const Banners = () => {
  return <BannersWrapper data-sentry-element="BannersWrapper" data-sentry-component="Banners" data-sentry-source-file="Banners.tsx">
      <RedirectBanner variant="trade" data-sentry-element="RedirectBanner" data-sentry-source-file="Banners.tsx" />
      <RedirectBanner variant="sell" data-sentry-element="RedirectBanner" data-sentry-source-file="Banners.tsx" />
      <RedirectBanner variant="freebie" data-sentry-element="RedirectBanner" data-sentry-source-file="Banners.tsx" />
      <QuickCashBanner data-sentry-element="QuickCashBanner" data-sentry-source-file="Banners.tsx" />
    </BannersWrapper>;
};