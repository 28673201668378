import styled, { css, keyframes } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { WHITE } from '@/styles/colors'
import { goldman } from '@/utils/fonts'

import { generalWrapperStyles } from '../generalStyles'

export const LandingWrapper = styled.main`
  margin-top: 60px;
  background-color: #141414;
  ${generalWrapperStyles(1600, false)}
  --padding: 16px;
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    --padding: 24px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    --padding: 36px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 70px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xxl}) {
    --padding: 48px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xxxl}) {
    margin-top: 80px;
  }
`

export const SectionTitle = styled.h2`
  width: 100%;
  padding: 0 20px;
  font-family: ${goldman.style.fontFamily};
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  text-wrap: balance;
  color: ${WHITE};

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 26px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 30px;
  }
`

export const floatKeyframes = keyframes`
  0%, 100% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(3px);
  }
`

export const floatAnimation = css`
  animation: ${floatKeyframes} 4s ease-in-out infinite;
`
