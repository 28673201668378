import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'

export const BannersWrapper = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
`
