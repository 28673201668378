import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'

export const FeaturedArticlesWrapper = styled.section`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    gap: 24px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 60px;
    gap: 60px;
  }
`

export const FeaturedArticlesList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
`
