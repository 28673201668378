import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, WHITE } from '@/styles/colors'
import { EASINGS, TIMINGS } from '@/styles/transitions'
import { lato } from '@/utils/fonts'

export const StepsColumn = styled.ul`
  --row-gap: 16px;

  padding: 12px 24px 0;
  display: flex;
  flex-direction: column;
  row-gap: var(--row-gap);

  & > li:nth-child(1) {
    animation-delay: 0s;
  }

  & > li:nth-child(2) {
    animation-delay: 2s;
  }

  & > li:nth-child(3) {
    animation-delay: 4s;
  }

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    --row-gap: 24px;
    grid-column: span 2;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    --row-gap: 36px;
    grid-column: span 1;
    padding: 0;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    --row-gap: 60px;
  }
`

export const StepWrapper = styled.li`
  --clr-base: ${GRAY.x800};
  --clr-title: ${GRAY.x500};
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  column-gap: 8px;
  color: var(--clr-base);
  transition: color ${TIMINGS.x03} ${EASINGS.movingIn};
  animation: highlight 6s infinite;
  cursor: default;
  will-change: color;

  &:not(&:last-of-type) {
    padding-bottom: var(--row-gap);
    border-bottom: 1px solid ${GRAY.x900};
  }

  & > svg {
    width: 24px;
    height: 24px;
    transition: inherit;
  }

  & > span {
    width: 2px;
    height: 24px;
    background-color: currentColor;
    transition: inherit;
    transition-property: background-color;
  }

  @keyframes highlight {
    0%,
    66.66% {
      --clr-base: ${GRAY.x800};
      --clr-title: ${GRAY.x500};
    }
    33.33% {
      --clr-base: ${GRAY.x50};
      --clr-title: ${WHITE};
    }
  }

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    column-gap: 20px;
    & > svg {
      width: 42px;
      height: 42px;
      transition: inherit;
    }
  }
`

export const StepTitle = styled.h3`
  font-family: ${lato.style.fontFamily};
  font-size: 16px;
  font-weight: 900;
  line-height: 25px;
  color: var(--clr-title);
  transition: inherit;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 20px;
  }
`

export const StepDescription = styled.p`
  grid-column: 3;
  font-family: ${lato.style.fontFamily};
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  color: var(--clr-base);
  transition: inherit;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 18px;
  }
`

export const StepsDivider = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${GRAY.x900};
  border: none;
`
