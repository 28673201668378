import Image from 'next/image'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, WHITE } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { montserrat } from '@/utils/fonts'

export const FeaturedArticleDecoShine = styled.div`
  position: absolute;
  top: -308px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  width: 308px;
  height: 308px;
  background-color: #fea235;
  border-radius: 50%;
  filter: blur(100px);
  transition: top ${TIMINGS.x01};
`

export const FeaturedArticleWrapper = styled(Link)`
  position: relative;
  padding: 20px;
  height: 300px;
  display: grid;
  grid-template-rows: 1fr auto auto;
  display: flex;
  gap: 28px;
  flex-direction: column;

  text-decoration: none;
  background-color: ${GRAY.x900};
  border-radius: 6px;
  overflow: hidden;
  transition: filter ${TIMINGS.x01};

  &:hover {
    filter: brightness(1.2);

    ${FeaturedArticleDecoShine} {
      top: -250px;
    }
  }
  &:active {
    filter: brightness(0.9);
  }

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    padding: 32px;
    height: 400px;
  }
`

export const FeaturedArticleImageWrapper = styled.div<{
  $isLarge?: boolean
}>`
  position: relative;
  margin: -32px -32px -24px -32px;
  max-height: 260px;
  height: 100%;
  width: auto;
  display: grid;
  place-items: center;
  overflow: hidden;

  ${({ $isLarge }) =>
    $isLarge &&
    css`
      margin: -20px -20px 0;

      @media (min-width: ${BREAKPOINTS_PX.lg}) {
        margin: -32px -32px 0;
      }
    `}
`

export const FeaturedArticleImage = styled(Image)<{
  $isLarge?: boolean
}>`
  margin: 0 auto;
  height: 100%;
  width: auto;
  max-width: ${({ $isLarge }) => ($isLarge ? '500px' : '100%')};
  object-fit: ${({ $isLarge }) => ($isLarge ? 'cover' : 'contain')};
  z-index: 1;
`
export const FeaturedArticleContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FeaturedArticleTitle = styled.h2`
  font-family: ${montserrat.style.fontFamily};
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: ${WHITE};
`

export const FeaturedArticleDescription = styled.p`
  font-family: ${montserrat.style.fontFamily};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${GRAY.x400};
  text-wrap: balance;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
