import { useTranslation } from 'next-i18next';
import ChatIcon from 'public/icons/chat-1-fill.svg';
import chickenDeco from 'public/images/help-center/chicken.webp';
import gunsDeco from 'public/images/help-center/guns.webp';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { toggleCrisp } from '@/utils/crisp';
import { HelpBannerButton, HelpBannerChickenDeco, HelpBannerDescription, HelpBannerGunsDeco, HelpBannerTitle, HelpBannerWrapper } from './HelpBanner.styles';
export interface HelpBannerProps {
  className?: string;
}
export const HelpBanner = ({
  className
}: HelpBannerProps) => {
  const {
    t
  } = useTranslation('helpCenter');
  const {
    data: steamProfileData
  } = useGetSteamProfile();
  return <HelpBannerWrapper className={className} data-sentry-element="HelpBannerWrapper" data-sentry-component="HelpBanner" data-sentry-source-file="HelpBanner.tsx">
      <HelpBannerTitle data-sentry-element="HelpBannerTitle" data-sentry-source-file="HelpBanner.tsx">{t('banner.title')}</HelpBannerTitle>
      <HelpBannerDescription data-sentry-element="HelpBannerDescription" data-sentry-source-file="HelpBanner.tsx">{t('banner.description')}</HelpBannerDescription>
      <HelpBannerButton leftComponent={<ChatIcon />} onClick={() => toggleCrisp(steamProfileData?.userId || '')} data-sentry-element="HelpBannerButton" data-sentry-source-file="HelpBanner.tsx">
        {t('banner.button')}
      </HelpBannerButton>
      <HelpBannerChickenDeco src={chickenDeco} alt="Chicken" draggable={false} data-sentry-element="HelpBannerChickenDeco" data-sentry-source-file="HelpBanner.tsx" />
      <HelpBannerGunsDeco src={gunsDeco} alt="Guns" draggable={false} data-sentry-element="HelpBannerGunsDeco" data-sentry-source-file="HelpBanner.tsx" />
    </HelpBannerWrapper>;
};