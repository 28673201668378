import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { lato } from '@/utils/fonts'

export const BlogPostsSection = styled.section`
  display: flex;
  margin-top: 24px;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 60px;
  }
`

export const BlogpostsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  row-gap: 28px;

  @media (min-width: ${BREAKPOINTS_PX.md}) {
    row-gap: 24px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    row-gap: 60px;
  }
`

export const BlogpostsDescription = styled.p`
  font-family: ${lato.style.fontFamily};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 20px;
    line-height: 24px;
    max-width: 50%;
  }
`
