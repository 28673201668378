import Link from 'next/link'
import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GRAY, WHITE } from '@/styles/colors'
import { TIMINGS } from '@/styles/transitions'
import { montserrat } from '@/utils/fonts'

export const ArticleButtonWrapper = styled(Link)`
  padding: 20px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 6px;
  background-color: ${GRAY.x900};
  text-decoration: none;
  color: ${WHITE};

  & > svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  transition: filter ${TIMINGS.x01};

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    height: 90px;
    padding: 28px;
  }

  &:hover {
    filter: brightness(1.3);
  }
  &:active {
    filter: brightness(0.9);
  }
`

export const HelpCenterArticlesItemTitle = styled.h4`
  font-family: ${montserrat.style.fontFamily};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
