import styled from 'styled-components'

import Image from '@/components/Image'
import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { BLUE, GRAY, LANDING_GRAY, WHITE } from '@/styles/colors'
import { addAlpha } from '@/utils/styles'

import { BannerDescription, BannerTitle } from './RedirectBanner.styles'

import { floatAnimation } from '../../landing.styles'

export const QuickCashBannerWrapper = styled.div`
  grid-column: 1/-1;
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border: 1px solid #2e2e2e;
  background-color: ${LANDING_GRAY};
  border-radius: 6px;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: -350px;
    left: 50%;
    transform: translateX(-50%);
    height: 1000px;
    aspect-ratio: 1/1;
    background-image: radial-gradient(
      circle at bottom,
      ${addAlpha(BLUE.x400, 1)} 0%,
      rgba(0, 0, 0, 0) 60%
    );
    z-index: 0;
  }

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    row-gap: 32px;
    &:after {
      top: -250px;
    }
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    padding: 64px 24px 48px 48px;
    row-gap: 32px;
    &:after {
      top: -200px;
    }
  }
`

export const QuickCashBannerTitle = styled(BannerTitle)`
  color: ${WHITE};
`

export const QuickCashBannerDescription = styled(BannerDescription)`
  color: ${GRAY.x400};
`

export const PayoutMethods = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${GRAY.x400};
  flex-wrap: wrap;
  max-width: 45%;
  z-index: 2;

  & > svg {
    height: 12px;
    width: auto;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
  }

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    column-gap: 48px;

    & > svg {
      height: 28px;
    }
  }
  @media (min-width: ${BREAKPOINTS_PX.xxl}) {
    max-width: unset;
  }
`

export const WeaponImageWrapper = styled.div`
  position: absolute;
  right: -128px;
  top: -5%;
  height: 110%;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    right: -156px;
  }
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    width: 60%;
    right: -20px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    width: unset;
    top: -10%;
    right: -128px;
    height: 120%;
    width: 720px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    right: -48px;
  }
`

export const StyledWeaponImage = styled(Image)`
  ${floatAnimation}
  animation-delay: -3s;
`

export const ImageBgBars = styled.div`
  display: none;
  align-items: center;
  column-gap: 44px;
  height: 50%;

  & > span {
    height: 100%;
    width: 2px;
    background-color: #2e2e2e;
  }

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    display: flex;
  }
`
