import styled from 'styled-components'

import { BREAKPOINTS_PX } from '@/styles/breakpoints'
import { GOLD, LANDING_GRAY, ORANGE, WHITE } from '@/styles/colors'
import { goldman, lato } from '@/utils/fonts'

export const StatisticsSection = styled.section`
  margin: 0 auto;
  margin-top: 24px;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    margin-top: 60px;
  }
  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    padding: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 64px;
    background-color: ${LANDING_GRAY};
    border: 1px solid #2e2e2e;
    border-radius: 6px;
  }
`

export const StatisticsNumbersWrapper = styled.div`
  color: ${GOLD.x300};
  gap: 16px;
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: max-content;
`

export const StatisticsNumber = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 8px;
  align-items: center;
  position: relative;

  svg {
    width: 24px;
    height: 24px;
    color: ${ORANGE.x400};
  }

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    column-gap: 12px;
    svg {
      width: 36px;
      height: 36px;
    }
  }
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    column-gap: 16px;
    svg {
      width: 44px;
      height: 44px;
    }
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    column-gap: 20px;
    svg {
      width: 52px;
      height: 52px;
    }
  }
`

export const Number = styled.p`
  font-family: ${goldman.style.fontFamily};
  font-size: 30px;
  font-weight: 400;
  margin: 0;
  z-index: 1;
  color: ${ORANGE.x400};

  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    font-size: 36px;
  }
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 42px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 48px;
  }
`

export const NumberAccent = styled.span`
  color: ${WHITE};
`

export const Description = styled.p`
  grid-column: 2;
  margin: 0;
  font-family: ${lato.style.fontFamily};
  font-weight: 400;
  font-size: 12px;
  z-index: 1;
  color: ${WHITE};
  @media (min-width: ${BREAKPOINTS_PX.sm}) {
    font-size: 13px;
  }
  @media (min-width: ${BREAKPOINTS_PX.md}) {
    font-size: 15px;
  }
  @media (min-width: ${BREAKPOINTS_PX.lg}) {
    font-size: 16px;
  }
`

export const StatisticsCopy = styled.header`
  display: none;
  flex-direction: column;
  flex: 1;
  align-items: center;
  row-gap: 34px;
  padding-left: 0px;
  text-align: left;

  @media (min-width: ${BREAKPOINTS_PX.xl}) {
    display: flex;
  }
`

export const StatisticsCopyHeading = styled.h5`
  font-family: ${goldman.style.fontFamily};
  color: ${WHITE};
  text-transform: uppercase;
  margin: 0;
  font-weight: 700;
  width: 100%;
  position: relative;
  font-size: 60px;
  line-height: 50px;
  text-wrap: balance;

  & > span {
    color: ${GOLD.x300};
  }
`

export const StatisticsCopyDesription = styled.p`
  font-family: ${lato.style.fontFamily};
  color: ${WHITE};
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-wrap: balance;
`
