import YtIcon from 'public/icons/youtube-landing.svg';
import { Flex } from '@/components/Flex';
import { ChannelAvatar, ChannelLabel, ChannelWrapper } from './Channel.styles';
export interface ChannelProps {
  avatar: string;
  name: string;
  subscriptions: string;
  link: string;
  className?: string;
}
export const Channel = ({
  avatar,
  className,
  name,
  link,
  subscriptions
}: ChannelProps) => {
  return <ChannelWrapper href={link} className={className} target="_blank" data-sentry-element="ChannelWrapper" data-sentry-component="Channel" data-sentry-source-file="Channel.tsx">
      <ChannelAvatar src={avatar} alt="" width={64} height={64} data-sentry-element="ChannelAvatar" data-sentry-source-file="Channel.tsx" />
      <Flex dir="column" rg={16} data-sentry-element="Flex" data-sentry-source-file="Channel.tsx">
        <Flex al="center" cg={10} data-sentry-element="Flex" data-sentry-source-file="Channel.tsx">
          <ChannelLabel data-sentry-element="ChannelLabel" data-sentry-source-file="Channel.tsx">{name}</ChannelLabel>
        </Flex>
        <Flex al="center" cg={10} data-sentry-element="Flex" data-sentry-source-file="Channel.tsx">
          <YtIcon style={{
          minWidth: '22px'
        }} data-sentry-element="YtIcon" data-sentry-source-file="Channel.tsx" />
          <ChannelLabel data-sentry-element="ChannelLabel" data-sentry-source-file="Channel.tsx">{subscriptions}</ChannelLabel>
        </Flex>
      </Flex>
    </ChannelWrapper>;
};