import { useTranslation } from 'next-i18next';
import ArrowRight from 'public/icons/arrow-right-s-fill.svg';
import { useRef } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { BREAKPOINTS } from '@/styles/breakpoints';
import { ArrowButton, CarouselWrapper, StyledCarousel } from './ChannelsCarousel.styles';
import { Channel, ChannelProps } from '../Channel/Channel';
export interface ChannelsCarouselProps {
  items: ChannelProps[];
}
export const ChannelsCarousel = ({
  items
}: ChannelsCarouselProps) => {
  const {
    t
  } = useTranslation('home');
  const ref = useRef<Carousel | null>(null);
  const handlePrev = () => {
    if (ref.current) {
      ref.current.goToSlide(ref.current.state.currentSlide - 1);
    }
  };
  const handleNext = () => {
    if (ref.current) {
      ref.current.goToSlide(ref.current.state.currentSlide + 1);
    }
  };
  return <CarouselWrapper data-sentry-element="CarouselWrapper" data-sentry-component="ChannelsCarousel" data-sentry-source-file="ChannelsCarousel.tsx">
      <ArrowButton $type="prev" onClick={handlePrev} variant="gray-fill" ariaLabel={t('trustedBy.previousChannelAriaLabel')} data-sentry-element="ArrowButton" data-sentry-source-file="ChannelsCarousel.tsx">
        <ArrowRight style={{
        rotate: '180deg'
      }} data-sentry-element="ArrowRight" data-sentry-source-file="ChannelsCarousel.tsx" />
      </ArrowButton>
      <StyledCarousel ref={ref} responsive={{
      sm: {
        breakpoint: {
          min: 0,
          max: BREAKPOINTS.sm
        },
        items: 1
      },
      md: {
        breakpoint: {
          min: BREAKPOINTS.sm,
          max: BREAKPOINTS.md
        },
        items: 2
      },
      lg: {
        breakpoint: {
          min: BREAKPOINTS.md,
          max: BREAKPOINTS.lg
        },
        items: 3
      },
      xl: {
        breakpoint: {
          min: BREAKPOINTS.lg,
          max: BREAKPOINTS.xxlPlus
        },
        items: 4
      },
      xxl: {
        breakpoint: {
          min: BREAKPOINTS.xxlPlus,
          max: 6000
        },
        items: 5
      }
    }} deviceType="xl" ssr autoPlay autoPlaySpeed={2000} swipeable={true} draggable={false} showDots={false} infinite={true} keyBoardControl={true} transitionDuration={500} arrows={false} data-sentry-element="StyledCarousel" data-sentry-source-file="ChannelsCarousel.tsx">
        {items.map((props, i) => <Channel {...props} key={i} />)}
      </StyledCarousel>
      <ArrowButton $type="next" onClick={handleNext} variant="gray-fill" ariaLabel={t('trustedBy.nextChannelAriaLabel')} data-sentry-element="ArrowButton" data-sentry-source-file="ChannelsCarousel.tsx">
        <ArrowRight data-sentry-element="ArrowRight" data-sentry-source-file="ChannelsCarousel.tsx" />
      </ArrowButton>
    </CarouselWrapper>;
};