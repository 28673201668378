import styled from 'styled-components'

import Image from '@/components/Image'
import { WHITE } from '@/styles/colors'
import { lato } from '@/utils/fonts'

export const ChannelWrapper = styled.a`
  display: flex;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
  min-width: 184px;
  text-decoration: none;
`

export const ChannelAvatar = styled(Image)`
  border-radius: 6px;
  width: 64px;
  height: 64px;
  object-fit: cover;
`

export const ChannelLabel = styled.p`
  font-family: ${lato.style.fontFamily};
  font-size: 18px;
  font-weight: 700;
  color: ${WHITE};
  white-space: nowrap;
`
